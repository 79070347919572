import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const VideoPage = () => {
	return (
		<Layout>
			<Seo title="Videos" />
			<h2>Videos</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/Jesse-high-7.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				height={333}
				/>
			</div>

			<h3>Live followed by Creative</h3>

			<h4>Live performances</h4>

			<h5>First ever Portuguese repertoire</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/385hxUuyDK4"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>Canada 2023 tour: highlights</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/Dl_tfFXkmx0"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>2022 house concert—1 of 2</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/rVbxSSLGWSg"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>2022 house concert—2 of 2</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/t2t-qJHI5oU"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>Blue Skies cover</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/OzCDgCoOcSo"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>2012 house concert</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/j8lOvtC7rUU"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>Want more?</h5>
			<p>Here's a <a href="//www.youtube.com/playlist?list=PLUnT5wM9jMYNtbd3nEsOysBoNa64JaBFO">whole playlist</a> of live performances spanning 15 years.</p>

			<h4>Creative videos</h4>

			<h5>Summer Grace</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/90qTotnvZa8"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>Nostargic</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/gtQiJHjLdVc"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>Close to Me</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/eHKHtglYaHw"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>The Igloo Song</h5>
			<iframe
			className="p"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/AE1-r0eD_dM"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />

			<h5>Want more?</h5>
			<p>Here's a <a href="//www.youtube.com/playlist?list=PLUnT5wM9jMYOz3_BIdbEImouAEQQRrZZx">whole playlist</a> of creative videos spanning 15 years.</p>
		</Layout>
	);
}

export default VideoPage;
